<template>
  <div class="page-view home">
    <div class="container">
      <div class="flex-wrapper">
        <div class="item">
          <h1 class="page-title"><v-icon name="bi-activity" />Statistiche</h1>
          <h3 class="subtitle">
            Da qui puoi controllare l'andamento
            del contest fotografico Conad <b>“Piacersi in uno Scatto”</b>
          </h3>
          <section class="stats" v-if="stats">
            <h6>L'attuale campagna riporta:</h6>
            <ul>
              <li v-for="(stat, index) in stats" :key="index" :class="{big: stat.val[0]}">
                <div class="inner-wrapper">
                  {{stat.label}}&nbsp;:&nbsp;
                  <b :class="{'changing' : this.animatedList.includes(stat.label)}"
                    v-if="stat.label !== 'Top 11 Punti Vendita'">
                    {{stat.val}}
                  </b>
                  <b :class="{'changing' : this.animatedList.includes(stat.label)}" v-else-if="stat.val[0]">
                    <ul class="pt-6">
                      <li class="border border-gray-500" v-for="(store, index) in stat.val" :key="store.id">
                        <p><strong>Posizione</strong> {{ index + 1 }}</p>
                        <h3>{{ store.business_name }} ({{ store.city }}, {{ store.region }})</h3>
                        <p><strong>Indirizzo:</strong> {{ store.address }}{{ store.fraction ? ', ' + store.fraction
                          : '' }}</p>
                        <p><strong>CAP:</strong> {{ store.zip_code }}</p>
                        <p><strong>Cooperativa:</strong> {{ store.cooperative }}</p>
                        <p><strong>Voti ricevuti:</strong> {{ store.votes }}</p>
                        <!-- <p><strong>Immagini:</strong></p>
                        <ul>
                          <li v-for="image in store.images" :key="image.id">
                            <img :src="image.url" alt="Store Image" style="width: 100px; height: auto;" />
                          </li>
                        </ul> -->
                      </li>
                    </ul>
                  </b>
                </div>
              </li>
            </ul>
          </section>
          <p class="pb-2">
            Scrivi qui sotto il tuo Indirizzo mail per effettuare l'invio del report relativo alla Classifica dei Voti
          </p>
          <div class="sm:flex justify-center mb-10 gap-4">
            <input type="email" v-model="email" required class="border-gray-400 px-4 border w-full py-2 text-lg sm:text-xl" />
            <button class="btn btn-orange flex justify-between gap-6 items-center text-white mt-4 sm:mt-0" @click="downloadExport">
              Esporta la classifica dei voti
              <!-- <img class="w-4" src="@/assets/img/download-icon.svg" /> -->
            </button>
            <button class="btn btn-orange flex justify-between gap-6 items-center text-white mt-4 sm:mt-0" @click="downloadExportPartner">
              Esporta la lista dei soci iscritti
              <!-- <img class="w-4" src="@/assets/img/download-icon.svg" /> -->
            </button>
            <button class="btn btn-orange flex justify-between gap-6 items-center text-white mt-4 sm:mt-0" @click="downloadExportCollaborator">
              Esporta la lista dei collaboratori iscritti
              <!-- <img class="w-4" src="@/assets/img/download-icon.svg" /> -->
            </button>
          </div>
        </div>
      </div>
    </div>
    <img class="bg-image" src="@/assets/img/speed-icon.svg" />
    <a class="logout" to="/login" href="#" @click.prevent="logout">
      ‹‹ Logout
      <b>{{stateMail}}</b>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import toISOString from '@/utils/toISOString.js'

export default {
  name: 'Home',
  data () {
    return {
      interval: null,
      loading: false,
      loading2: true,
      history: null,
      stats: null,
      whatToExport: 'all',
      counter: 0,
      queryLimit: 18,
      email: null,
      categories: [
        'in',
        'user_create',
        'item_created',
        'partecipation_created_lost',
        'partecipation_created_win',
        'sent',
        'delivered',
        'blocked_max_part',
        'error'
      ],
      testQuery: this.$route.query.test,
      animatedList: [],
      tablePaging: 0
    }
  },
  computed: {
    ...mapGetters([
      'stateMail'
    ]),
    getHistory () {
      return this.history
    }
  },
  methods: {
    toISOString,
    async downloadExport () {
      const payload = {
        recipients: [
          this.email
        ]
      }
      await this.$store.dispatch('sendMailCompletePOS', payload)
        .then((rsp) => {
          let msg, error
          if (rsp) {
            msg = 'Email inviata correttamente all\'indirizzo: ' + this.email
            error = false
          } else {
            msg = 'ERRORE: problema tecnico. Riprovare più tardi.'
            error = true
          }
          this.emitter.emit('fireAlert', { msg, error })
          // window.alert(msg)
        })
    },
    async downloadExportPartner () {
      const payload = {
        recipients: [
          this.email
        ]
      }
      await this.$store.dispatch('sendMailExportPartner', payload)
        .then((rsp) => {
          let msg, error
          if (rsp) {
            msg = 'Email inviata correttamente all\'indirizzo: ' + this.email
            error = false
          } else {
            msg = 'ERRORE: problema tecnico. Riprovare più tardi.'
            error = true
          }
          this.emitter.emit('fireAlert', { msg, error })
          // window.alert(msg)
        })
    },
    async downloadExportCollaborator () {
      const payload = {
        recipients: [
          this.email
        ]
      }
      await this.$store.dispatch('sendMailExportCollaborator', payload)
        .then((rsp) => {
          let msg, error
          if (rsp) {
            msg = 'Email inviata correttamente all\'indirizzo: ' + this.email
            error = false
          } else {
            msg = 'ERRORE: problema tecnico. Riprovare più tardi.'
            error = true
          }
          this.emitter.emit('fireAlert', { msg, error })
          // window.alert(msg)
        })
    },
    async getStats (animated) {
      this.animatedList = []
      if (!animated) this.loading = true
      await this.$store.dispatch('getStats')
        .then((rsp) => {
          const temp = []
          for (let i = 0; i < Object.keys(rsp).length; i++) {
            const lbl = Object.keys(rsp)[i]
            const val = Object.values(rsp)[i]
            const translated = {
              label: null,
              val
            }
            switch (lbl) {
              case 'users_count':
                translated.label = 'Totale utenti'
                break
              case 'users_count_active':
                translated.label = 'Totale utenti attivi'
                break
              case 'user_reward_assignments_count':
                translated.label = 'Totale premi assegnati'
                break
              case 'items_count':
                translated.label = 'Totale SMS validi'
                break
              case 'received_sms_count':
                translated.label = 'SMS ricevuti'
                break
              case 'sms_sent':
                translated.label = 'SMS inviati'
                break
              case 'partecipation_count':
                translated.label = 'Totale partecipazioni'
                break
              case 'received_whatsapp_count':
                translated.label = 'Whatsapp ricevuti'
                break
              case 'whatsapp_sent':
                translated.label = 'Whatsapp inviati'
                break
              case 'whatsapp_error_messagges_sent':
                translated.label = 'Whatsapp ERRORE inviati'
                break
              case 'sms_error_messagges_sent':
                translated.label = 'SMS ERRORE inviati'
                break
              case 'items_not_moderated_count':
                translated.label = 'Partecipazioni NON moderate'
                break
              case 'items_not_rejected_count':
                translated.label = 'Partecipazioni NON rifiutate'
                break
              case 'items_rejected_count':
                translated.label = 'Partecipazioni rifiutate'
                break
              case 'active_collaborator_count':
                translated.label = 'Collaboratori attivi'
                break
              case 'active_partner_count':
                translated.label = 'Soci attivi'
                break
              case 'completed_points_of_sale_count':
                translated.label = 'Punti Vendita con caricamento foto completato'
                break
              case 'top_11_points_of_sales':
                translated.label = 'Top 11 Punti Vendita'
                break
              case 'voting_users_count':
                translated.label = 'Utenti che hanno votato'
                break
              default:
                translated.label = lbl
            }
            if (translated.label !== 'Totale SMS validi') temp.push(translated)
            temp.sort(function (a, b) {
              return (a.label < b.label) ? -1 : (a.label > b.label) ? 1 : 0
            })
          }
          if (animated) {
            for (let i = 0; i < temp.length; i++) {
              if (!this.stats || !this.stats[i] || this.stats[i].val !== temp[i].val) {
                this.animatedList.push(temp[i].label)
              }
            }
            // console.log('TO ANIMATE', this.animatedList)
          }
          this.stats = temp
          setTimeout(() => {
            this.loading = false
          }, 3000)
        })
        .catch((e) => {
          console.error('GOING TO LOGOUT', e)
          this.$router.push('/login')
        })
      if (!this.stats) this.$router.push('/login')
    },
    async logout () {
      await this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
    }
  },
  watch: {
    $route (to, from) {
      clearInterval(this.interval)
    }
  },
  async mounted () {
    // console.log('---STORE', this.$store.state)
    // await this.getItems()
    this.loading2 = false
    this.getStats(false)
    this.interval = setInterval(() => {
      // console.log('check...')
      this.getStats(true)
        .then(async () => {
          if (this.animatedList.length) await this.searchLogs()
        })
      setTimeout(() => {
        this.animatedList = []
      }, 15000)
    }, 300000) // (5 min)
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/tables.scss";
  @import "@/assets/scss/views/home.scss";
</style>
